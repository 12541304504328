<template>
  <MainLayout v-if="hasTemplates">
    <div class="q-pa-lg q-gutter-y-lg">
      <div class="header border-radius-10 border flex justify-between items-center q-px-lg">
        <div
          v-t="'title.selectTemplate'"
          class="text-18-700"
        />
        <AppButtonLink
          icon-left="close"
          icon-left-size="25px"
          icon-left-color="grey-7"
          padding="14px 0"
          :to="{ name: ENUMS.ROUTES.DASHBOARD.PAGES }"
        />
      </div>
      <div class="border-radius-10 border">
        <div class="row q-pa-lg q-col-gutter-lg">
          <div
            v-for="template in pageTemplates"
            :key="template.id"
            class="col-3"
          >
            <TemplateItem :template="template"/>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import handleError from 'common/helpers/handleError';
import MainLayout from 'common/layouts/MainLayout';
import TemplateItem from 'src/pages/dashboard/components/TemplateItem';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TemplatesPage',
  components: {
    TemplateItem,
    AppButtonLink,
    MainLayout,
  },
  computed: {
    ...mapState('dashboard', ['pageTemplates']),
    hasTemplates() {
      return this.pageTemplates.length > 0;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions('dashboard', ['fetchPageTemplates']),
    async fetch() {
      try {
        this.$q.loading.show();
        await this.fetchPageTemplates();
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 70px;
}
</style>
