<template>
  <div
    class="border-radius-10 overflow-hidden cursor-pointer"
    @mouseenter="hasHover = true"
    @mouseleave="hasHover = false"
    @click="create(template.id)"
  >
    <OverlayControls
      :show="hasHover"
      padding="lg"
      blackout
    >
      <q-img
        :src="template.preview.url"
        height="200px"
      />
      <template #controls>
        <div class="fit column text-white">
          <div class="col">
            <div class="text-14-700 q-mb-xs">{{ template.title }}</div>
            {{ template.description.short }}
          </div>
          <div>
            <AppButton
              :label="$t('button.preview')"
              label-class=""
              color="white"
              class="text-14-400"
              text-color="black"
              padding="2px 10px"
              @click.stop="showPreview = true"
            />
          </div>
        </div>
      </template>
    </OverlayControls>

    <AppModal
      v-if="showPreview"
      :title="$t('title.preview')"
      parent-class=""
      content-class="preview-template"
      is-open
      show-close-btn
      @close="showPreview = false"
    >
      <Render
        :options="template.jsonSchema"
        is-preview
      />
    </AppModal>
  </div>
</template>

<script>
import AppModal from 'common/components/AppModal';
import OverlayControls from 'common/components/OverlayControls';
import AppButton from 'common/components/buttons/AppButton';
import Render from 'common/components/render/Render';
import handleError from 'common/helpers/handleError';
import { mapActions } from 'vuex';

export default {
  name: 'TemplateItem',
  components: {
    AppButton,
    AppModal,
    OverlayControls,
    Render,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasHover: false,
      showPreview: false,
    };
  },
  methods: {
    ...mapActions('dashboard', ['createPage']),
    async create(templateId) {
      try {
        this.$q.loading.show();
        const pageGuid = await this.createPage(templateId);
        await this.$router.push({
          name: ENUMS.ROUTES.DASHBOARD.VIEW_PAGE,
          params: { pageGuid },
        });
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.preview-template {
  border-radius: 0;

  .modal-header {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
</style>
